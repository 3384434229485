import React from 'react'
import Section from './Section'
import Services from './Services'

function Home() {
  return (
    <>
      <Section/>
      <Services/>
    </>
  )
}

export default Home
